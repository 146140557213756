<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('student_section_change')" :isNewButton="false" :isColumns="false"
                        @filter-div-status="filterStatus = $event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('student_section_change')" :isNewButton="false" :isColumns="false"
                              @filter-div-status="filterStatus = $event">
                </HeaderMobile>
            </template>
            <datatable-filter v-show="filterStatus" @filter="filter" @filterClear="clearFilterData">
                <ValidationObserver ref="filterForm">
                    <b-row>
                        <b-col sm="12" md="6" lg="4">
                            <ValidationProvider name="semester_id" rules="required" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('period')">
                                    <semesters-selectbox v-model="filterData.semester_id" :validate-error="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="faculty_code" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('faculty')">
                                    <faculty-selectbox v-model="filterData.faculty_code" :validateError="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="department_code" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('department')">
                                    <department-selectbox v-model="filterData.department_code"
                                                          :faculty_code="filterData.faculty_code"
                                                          :validateError="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="program_code" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('program')">
                                    <program-selectbox v-model="filterData.program_code"
                                                       :faculty_code="filterData.faculty_code"
                                                       :department_code="filterData.department_code"
                                                       :validateError="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="12" md="6" lg="4">
                            <ValidationProvider name="student_number" :touched="false" rules=""
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('student_number')">
                                    <multi-text :enterSearchDisable="true" v-model="filterData.student_number"
                                                :height="'260px'" :validate-error="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="12" md="6" lg="4">
                            <ValidationProvider name="course_id" rules="required" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('course')">
                                    <course-auto-complete v-model="filterData.course_id" :validate-error="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="section" rules="" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('section')">
                                    <b-form-input v-model="filterData.section" type="number"
                                                  :state="errors[0] ? false : null">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"
                                                             class="mb-2"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>

                            <ValidationProvider name="type" rules="required" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('type')">
                                    <b-form-select v-model="filterData.type" :options="typeOptions"></b-form-select>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"
                                                             class="mb-2"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>

                            <ValidationProvider name="instructor_id" v-slot="{ valid, errors }">
                                <b-form-group :label="$t('instructor')">
                                    <staff-auto-complete v-model="filterData.instructor_id"
                                                         :validate-error="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </datatable-filter>
            <div v-if="rows && rows.students">
                <h5 class="mt-3">{{ $t('student_sections').toUpper() }}</h5>
                <div v-if="rows.students.length > 0">
                    <b-table :empty-filtered-text="$t('table_not_found')" :empty-text="$t('table_is_empty')" bordered
                             striped responsive :items="rows.students" :fields="rowFields"
                             class="mb-3 table-dropdown no-scrollbar border rounded">
                        <template #cell(number)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template #cell(section)="data">
                            {{ data.item.section ? data.item.section : '-' }}
                        </template>
                        <template #cell(new_section)="data">
                            <b-form-select v-model="updateData.data[data.index].section_id" :options="rows.sections"
                                           value-field="id" text-field="section"/>
                        </template>
                    </b-table>
                    <b-button variant="primary" @click="update">{{ $t('update') }}</b-button>
                </div>
                <div v-else>
                    <div class="border p-4 rounded-sm">
                        {{ $t('no_result') }}
                    </div>
                </div>
            </div>
            <CommonModal :size="'lg'" ref="informModal">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('information') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div v-for="item in informData" :key="item.student_course_id">
                        <b-alert v-if="item.status == 'updated'" show variant="success">
                            {{
                                $t('section_update_success',
                                    {
                                        name: getStudent(item.student_course_id).name,
                                        surname: getStudent(item.student_course_id).surname,
                                        section: item.lab_section,
                                    })
                            }}
                        </b-alert>
                        <b-alert v-else show variant="danger">
                            <div v-if="item.overlap_data">
                                <div v-html="$t('section_update_unsuccess',
                                    {
                                        name: getStudent(item.student_course_id).name,
                                        surname: getStudent(item.student_course_id).surname,
                                        section: item.section,
                                        course_code: item.overlap_data?.course_code,
                                        day_name: item.overlap_data?.day.name,
                                        start_time: item.overlap_data?.start_time,
                                        end_time: item.overlap_data?.end_time,
                                        add_info: item.status == 'overlap' ? $t('section_update_overlap') : null
                                    })">
                                </div>
                            </div>
                            <div v-else v-html="$t('section_update_unsuccess2',{
                                 section: item.section,
                            })">

                            </div>


                        </b-alert>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";

// Components
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox"
import MultiText from "@/components/interactive-fields/MultiText";
import CourseAutoComplete from "@/components/interactive-fields/CourseAutoComplete"
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox"
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox"
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox"
import StaffAutoComplete from "@/components/interactive-fields/StaffAutoComplete";
import CommonModal from '@/components/elements/CommonModal';

// Services
import StudentCourseService from "@/services/StudentCourseService";

// Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
import qs from 'qs'

export default {
    name: "StudentCoursesSectionUpdate",
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,

        SemestersSelectbox,
        MultiText,
        CourseAutoComplete,
        FacultySelectbox,
        DepartmentSelectbox,
        ProgramSelectbox,
        StaffAutoComplete,
        CommonModal,

        ValidationProvider,
        ValidationObserver,
    },
    metaInfo() {
        return {
            title: this.$t('student_section_change')
        }
    },
    data() {
        return {
            filterData: {},
            filterStatus: true,
            rows: [],
            rowsBeforeUpdate: [],
            rowFields: [
                {
                    key: 'number',
                    label: this.$t('number'),
                    thClass: 'text-center',
                    tdClass: 'text-center'
                },
                {
                    key: this.getLocaleField('program_name'),
                    label: this.$t('program').toUpper()
                },
                {
                    key: 'student_number',
                    label: this.$t('student_number').toUpper()
                },
                {
                    key: 'name',
                    label: this.$t('name').toUpper()
                },
                {
                    key: 'surname',
                    label: this.$t('surname').toUpper()
                },
                {
                    key: 'class',
                    label: this.$t('class').toUpper(),
                    thClass: 'text-center',
                    tdClass: 'text-center'
                },
                {
                    key: 'credit',
                    label: this.$t('credit').toUpper(),
                    thClass: 'text-center',
                    tdClass: 'text-center'
                },
                {
                    key: 'section',
                    label: this.$t('current_section').toUpper(),
                    thClass: 'text-center',
                    tdClass: 'text-center'
                },
                {
                    key: 'new_section',
                    label: this.$t('new_section').toUpper(),
                    thClass: 'text-center',
                    tdClass: 'text-center'
                }
            ],
            typeOptions: [
                {value: 1, text: "Teorik"},
                {value: 2, text: "Pratik"},
            ],
            updateData: [],
            sectionList: [],
            informData: []
        }
    },
    created() {
        this.clearFilterData()
    },
    methods: {
        clearFilterData() {
            this.filterData = {
                semester_id: null,
                faculty_code: null,
                department_code: null,
                program_code: null,
                student_number: null,
                section: null,
                course_id: null,
                instructor_id: null,
                type: 1
            }
            this.rows = [];
            //this.$refs.filterForm.reset();
        },
        async filter(e) {
            if (e && e.key === "Enter" && e.target.nodeName === "TEXTAREA") {
                return
            }

            const isValid = await this.$refs.filterForm.validate();
            if (isValid) {
                const config = {
                    params: {
                        ...this.filterData,
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }

                this.isLoading = true;

                this.rows = [];
                this.updateData = {
                    data: [],
                    type: this.filterData.type
                }

                this.sectionList = {
                    data: [],
                    type: this.filterData.type
                }

                return StudentCourseService.sectionUpdateList(config)
                    .then((response) => {
                        this.rows = response.data.data;
                        if (this.rows.students) {
                            this.rows.students.forEach((item, key) => {
                                this.updateData.data.push({
                                    student_course_id: item.id,
                                    section_id: item.section_id
                                })
                            });
                            this.sectionList.data = JSON.parse(JSON.stringify(this.updateData.data))
                        }
                    })
                    .catch(error => {
                        this.showErrors(error)
                    })
                    .finally(() => {
                        this.isLoading = false;
                    })
            }
        },
        getChangedData() {
            const changedData = JSON.parse(JSON.stringify(this.updateData))

            changedData.data = this.updateData.data.filter((item, index) => {
                return item.section_id != this.sectionList.data[index].section_id
            })

            return changedData
        },
        update() {
            const data = this.getChangedData()
            return StudentCourseService.sectionUpdate(data)
                .then((response) => {
                    this.informData = response.data.data
                    this.rowsBeforeUpdate = this.rows
                    this.$refs.informModal.$refs.commonModal.show()
                    this.filter()
                })
                .catch(error => {
                    this.showErrors(error)
                })
        },
        getStudent(id) {
            return this.rowsBeforeUpdate?.students?.find(item => item.id == id) ?? ''
        }
    }
}
</script>

